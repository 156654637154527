<template>
    <v-app>
        <div class="printContacts" id="printContacts">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        dark
                        class="buttons--add order__btn no-print"
                        style="margin-right: 10px, z-index: 5"
                        fab
                        small
                        @click="print()"
                    >
                        <v-icon size="18" dark>mdi-printer</v-icon>
                    </v-btn>
                </template>
                <span>Drukuj kontakty</span>
            </v-tooltip>
            <div id="firsPage">
                <div class="mt-5">
                    <p class="title-firstPage">Wojewódzki Szpital Dziecięcy</p>
                    <p class="title-firstPage pb-5">Spis telefonów</p>
                    <p class="text-firstPage pt-5">
                        Wykonywanie połączeń z telefonów stacjonarnych na zewnątrz poprzedza wybieranie numeru
                        <b>0</b>.
                    </p>
                    <p
                        class="text-firstPage"
                    >Połączenia bezpośrednie na numery wewnętrzne z zewnątrz możliwe są wyłącznie do numeru 299.</p>
                    <p class="text-firstPage">
                        <b>Ze względu na ograniczoną obecnie funkcjonalność centrali telefonicznej, od numeru wewnętrznego 300 możliwy jest bezpośredni kontakt telefoniczny z zewnątrz tylko za pośrednictwem centrali WSD!</b>
                    </p>
                    <img class="contactPrintImg" src="@/assets/img/wsd/wsd.png" alt="logo" />
                    <p class="text-firstPage">
                        <b>Nie należy również wybierać bezpośredniego numeru ze służbowych telefonów komórkowych.</b>
                    </p>
                    <p class="text-firstPage">
                        W związku z tym nie należy osobom z zewnątrz podawać numerów bezpośrednich. Wybieranie numeru np. 52 32-62-
                        <b>494</b> połączy rozmówcę z innymi osobami na rerenie Bydgoszczy, które są abonentami tych numerów
                    </p>
                </div>
            </div>
        </div>
        <div class="printContacts mt-1">
            <div class="printContentWSD">
                <h2 class="my-5 py-5 title-secondPage" style="text-align: center">
                    WOJEWÓDZKI SZPITAL DZIECIĘCY im. J. Brudzińskiego w Bydgoszczy
                    Lokalizacja- ul.Chodkiewicza 44, 85-667 Bydgoszcz
                </h2>

                <div>
                    <div v-for="category in allCategory " :key="category.id">
                        <h2
                            class="my-5 py-5 category-title"
                        >{{category.departments[0] ? category.name : ''}}</h2>

                        <table
                            border="1"
                            v-for="departments in category.departments"
                            :key="departments.id"
                        >
                            <!-- v-for="arr in category.arr" :key="arr.id" -->
                            <tr>
                                <th colspan="4">
                                    {{departments.name ? departments.name : ""}}
                                    <p
                                        style="font-size: 12px"
                                        class="ma-0 pa-0"
                                    >{{departments.location ? departments.location : ''}}</p>
                                </th>
                            </tr>
                            <tr v-for="person in departments.users" :key="person.id">
                                <td style="width: 250px">
                                    <b
                                        v-if="person.organizationalUnit"
                                    >{{person.organizationalUnit ? person.organizationalUnit : ''}}:</b>
                                    <span>{{person.worker ? ' ' + person.worker.name[0] : ''}}. {{person.worker ? person.worker.lastname : ''}}</span>
                                </td>
                                <td style="width: 150px">{{person.phone ? person.phone : ''}}</td>
                                <td
                                    style="width: 100px"
                                >{{person.landlinePhone ? person.landlinePhone : ''}}</td>
                                <td style="width: 270px">{{person.email ? person.email : ''}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div v-if="emptyCategory.length != 0">
                    <h2 class="my-5 py-5 category-title">Inne (kontakt bez przypisanej kategorii)</h2>
                    <table border="1">
                        <tr v-for="person in emptyCategory[0]" :key="person.id">
                            <td style="width: 250px">
                                <b
                                    v-if="person.organizationalUnit"
                                >{{person.organizationalUnit ? person.organizationalUnit : ''}}:</b>
                                <span>{{person.worker ? ' ' + person.worker.name[0] : ''}}. {{person.worker ? person.worker.lastname : ''}}</span>
                            </td>
                            <td style="width: 150px">{{person.phone ? person.phone : ''}}</td>
                            <td
                                style="width: 100px"
                            >{{person.landlinePhone ? person.landlinePhone : ''}}</td>
                            <td style="width: 270px">{{person.email ? person.email : ''}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div v-for="n in pages" :key="n" class="addPage mt-1"></div>
    </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import Snackbars from "@/components/Global/Snackbars/Snackbars.vue";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

export default {
    data: () => ({
        allCategory: [],
        pages: 0,
        emptyCategory: [],
    }),
    computed: {
        ...mapGetters(["getPrintContacts", "getWSDCategoryDepartments"]),
    },

    methods: {
        checkHeight() {
            let ElementOffsetHeight = document.querySelector(".printContentWSD")
                .offsetHeight;
            let pagesHeight = document.querySelector(".printContacts")
                .offsetHeight;

            let pages = ElementOffsetHeight / pagesHeight;
            this.pages = Math.ceil(pages) - 1;
        },
        category() {
            let filterCategory = [];
            let allCategory = [];
            let UserDepartmetns = [];
            let DepartmentsArr = [];
            let emptyCategory = [];
            let category = this.getWSDCategoryDepartments;

            // Przypisanie do tablicy bez kategorii
            let other = this.getPrintContacts.filter(
                (item) => !item.unit.category
            );
            if (other.length != 0) {
                emptyCategory.push(other);
                this.emptyCategory = emptyCategory;
            }
            // ++++++++++++++++++++++++
            for (let index = 0; index < category.length; index++) {
                // Sprawdzanie czy jest w kategorii oddział
                let filterCategory = this.getPrintContacts.filter(
                    (item) => item.unit.category == category[index]
                );

                let allDepartamentName = [];
                let departmentsName = [];
                // wyciąganie nazwy departamentów (wszystkich)
                filterCategory.forEach((element) => {
                    allDepartamentName.push(element);
                });
                // usuwanie zdublowanych nazw
                filterCategory.forEach((element) => {
                    departmentsName.push(element.unit.fullName);
                });

                let uniqueDepartmentsName = departmentsName.filter(
                    (item, index) => departmentsName.indexOf(item) === index
                );
                let uniqueLocationName = departmentsName.filter(
                    (item, index) => departmentsName.indexOf(item) === index
                );

                // przypisanie użytkowników do departamentu
                let DepartmentsArr = [];

                for (
                    let index = 0;
                    index < uniqueDepartmentsName.length;
                    index++
                ) {
                    this.UserDepartmetns = allDepartamentName.filter(
                        (item) =>
                            item.unit.fullName == uniqueDepartmentsName[index]
                    );

                    DepartmentsArr.push({
                        name: uniqueDepartmentsName[index],
                        location: this.UserDepartmetns[0].location
                            ? this.UserDepartmetns[0].location
                            : "",
                        users: this.UserDepartmetns,
                    });
                }
                this.DepartmentsArr = DepartmentsArr;
                allCategory.push({
                    name: category[index],
                    departments: DepartmentsArr,
                });
            }
            this.allCategory = allCategory;
        },

        print() {
            window.print();
            // this.$router.push("/service");
        },

        sendToEmail() {
            let node = document.getElementById("firsPage");
            const scale = 2;
            let obj = {
                height: 1200 * scale,
                style: {
                    transform: `scale(${scale}) translate(${
                        1900 / 2 / scale
                    }px, ${1100 / 2 / scale}px)`,
                },
                width: 1100 * scale,
            };

            let client = this.clientEmail;
            let index = this.getServiceDetails.index;

            if (client === "") {
                this.errorMessages = "Pole wymagane";
            } else {
                setTimeout(function () {
                    document.getElementById(
                        "order"
                    ).innerHTML = `<div class="printContent"><div class="printQRCode" style="font-size: 18px; text-align: center; line-height: 2; width: 250px">${"Trwa wysyłanie maila proszę czekać..."}</div><div class="printQRCode" style="font-size: 11px"></div></div><div id="preloader"><div id="loader">
                                        </div></div>`;
                }, 100);
                document.body.style.overflow = "hidden !important";
                this.errorMessages = "";
                let that = this;
                this.menu = false;

                const chars =
                    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

                let code = "";
                for (let i = 0; i < 6; i++) {
                    let index = Math.floor(Math.random() * chars.length);
                    code += chars[index];
                }
                domtoimage.toPng(node, obj).then(function (dataUrl) {
                    let img = new Image();
                    img.src = dataUrl;
                    let doc = new jsPDF();
                    doc.internal.scaleFactor = 6.1;
                    doc.addImage(img, "png", -60, 15);
                    var fd = new FormData();
                    fd.append("case", "pdf");
                    fd.append("mail", `${client}`);
                    fd.append("index", `${index}`);
                    fd.append("code", `${code}`);
                    fd.append("upl", doc.output("blob"));

                    Vue.axios({
                        method: "POST",
                        url: `/sendOrder`,
                        data: fd,
                    })
                        .then(() => {
                            that.setSnackbars({
                                type: "success",
                                text: "Załącznik został wysłany",
                            });
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        })
                        .catch(() => {
                            that.setSnackbars({
                                type: "error",
                                text: "Ups! Błąd wysyłania",
                            });

                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        });
                });
            }
        },
    },
    beforeMount() {
        this.category();
    },
    mounted() {
        this.checkHeight();
    },
};
</script>

<style lang="sass" scoped>
h1
    font-size: 10rem
    color: red
</style>
